

export default {
    setup() {
        const infos = [
            ' ICP备案:  沪ICP备17057428号-4'
        ]
        return {
            infos
        }
    },
}
